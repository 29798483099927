import { render, staticRenderFns } from "./default.vue?vue&type=template&id=0872337a"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"
import style0 from "./default.vue?vue&type=style&index=0&id=0872337a&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppHeader: require('/opt/build/repo/src/client/components/app-header/app-header.vue').default,CookieBanner: require('/opt/build/repo/src/client/components/cookie-banner/cookie-banner.vue').default,SectionItem: require('/opt/build/repo/src/client/components/section-item/section-item.vue').default,CategoryLayout: require('/opt/build/repo/src/client/components/category-layout/category-layout.vue').default,AppFooterDesktop: require('/opt/build/repo/src/client/components/app-footer-desktop/app-footer-desktop.vue').default,AppFooterMobile: require('/opt/build/repo/src/client/components/app-footer-mobile/app-footer-mobile.vue').default,AppSnackbar: require('/opt/build/repo/src/client/components/app-snackbar/app-snackbar.vue').default,ChatButton: require('/opt/build/repo/src/client/components/chat-button/chat-button.vue').default,NewsletterToastActivator: require('/opt/build/repo/src/client/components/newsletter-toast-activator/newsletter-toast-activator.vue').default,AppOverlay: require('/opt/build/repo/src/client/components/app-overlay/app-overlay.vue').default,AppNotice: require('/opt/build/repo/src/client/components/app-notice/app-notice.vue').default,SocialRetailActivator: require('/opt/build/repo/src/client/components/social-retail-activator/social-retail-activator.vue').default})
