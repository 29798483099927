import { render, staticRenderFns } from "./product-carousel.vue?vue&type=template&id=90177418"
import script from "./product-carousel.vue?vue&type=script&lang=js"
export * from "./product-carousel.vue?vue&type=script&lang=js"
import style0 from "./product-carousel.vue?vue&type=style&index=0&id=90177418&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SmartLink: require('/opt/build/repo/src/client/components/smart-link/smart-link.vue').default,ProductCardSkeleton: require('/opt/build/repo/src/client/components/product-card-skeleton/product-card-skeleton.vue').default,ProductCard: require('/opt/build/repo/src/client/components/product-card/product-card.vue').default,CustomButtonItem: require('/opt/build/repo/src/client/components/custom-button-item/custom-button-item.vue').default,FixedRatio: require('/opt/build/repo/src/client/components/fixed-ratio/fixed-ratio.vue').default,HorizontalCarousel: require('/opt/build/repo/src/client/components/horizontal-carousel/horizontal-carousel.vue').default,AppButton: require('/opt/build/repo/src/client/components/app-button/app-button.vue').default})
