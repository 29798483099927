import axios from 'axios'

import { CATEGORY_PAGE_NAME } from '~/router'

/**
 * @typedef StaticDataService
 * @alias this.$staticDataService
 */
export class StaticDataService {
  #locale

  constructor(
    locale
  ) {
    this.#locale = locale

    this.cmsApp = null
    this.version = null
    this.categoriesIndex = null
    this.categoryMappingsIndex = null
    this.cmsTranslatedSlugs = null
    this.cmsErrorPageDefault = null
    this.cmsLayoutsDefault = null
    this.cmsPagesCampaigns = null
    this.shippingCountriesIndex = null
    this.cmsSearchPagesIndex = null
  }

  async init() {
    this.cmsApp =  await this.getCmsApp()
    this.version = this.cmsApp.version

    const [
      categoriesIndex,
      categoryMappingsIndex,
      cmsTranslatedSlugs,
      cmsErrorPageDefault,
      cmsLayoutsDefault,
      cmsPagesCampaigns,
      shippingCountriesIndex,
      cmsSearchPagesIndex,
    ] = await Promise.all([
      this.getCategoriesIndex(),
      this.getCategoryMappingsIndex(),
      this.getCmsTranslatedSlugs(),
      this.getCmsErrorPageDefault(),
      this.getCmsLayoutsDefault(),
      this.getCmsPagesCampaigns(),
      this.getShippingCountriesIndex(),
      this.getCmsSearchPagesIndex(),
    ])

    this.categoriesIndex = categoriesIndex
    this.categoryMappingsIndex = categoryMappingsIndex
    this.cmsTranslatedSlugs = cmsTranslatedSlugs
    this.cmsErrorPageDefault = cmsErrorPageDefault
    this.cmsLayoutsDefault = cmsLayoutsDefault
    this.cmsPagesCampaigns = cmsPagesCampaigns
    this.shippingCountriesIndex = shippingCountriesIndex
    this.cmsSearchPagesIndex = cmsSearchPagesIndex
  }

  /**
   * @returns {Promise<Object>}
   */
  async getCmsApp() {
    return process.server
      ? JSON.parse(require('fs').readFileSync(`src/client/static/data/cms/app.json`, 'utf8'))
      : await axios.get(`/data/cms/app.json`).then(response => response.data)
  }

  /**
   * @returns {Promise<Object>}
   */
  async getCategoriesIndex() {
    return process.server
      ? JSON.parse(require('fs').readFileSync(`src/client/static/data/categories/index.json`, 'utf8'))
      : await axios.get(`/data/categories/index.json?_=${this.version}`).then(response => response.data)
  }

  /**
   * @returns {Promise<Object>}
   */
  async getCategoryMappingsIndex() {
    return process.server
      ? JSON.parse(require('fs').readFileSync(`src/client/static/data/category-mappings/${this.#locale}/index.json`, 'utf8'))
      : await axios.get(`/data/category-mappings/${this.#locale}/index.json?_=${this.version}`).then(response => response.data)
  }

  /**
   * @returns {Promise<Object>}
   */
  async getCmsTranslatedSlugs() {
    return process.server
      ? JSON.parse(require('fs').readFileSync(`src/client/static/data/cms/translated-slugs.json`, 'utf8'))
      : await axios.get(`/data/cms/translated-slugs.json?_=${this.version}`).then(response => response.data)
  }

  /**
   * @returns {Promise<Object>}
   */
  async getCmsErrorPageDefault() {
    return process.server
      ? JSON.parse(require('fs').readFileSync(`src/client/static/data/cms/${this.#locale}/error-page/default.json`, 'utf8'))
      : await axios.get(`/data/cms/${this.#locale}/error-page/default.json?_=${this.version}`).then(response => response.data)
  }

  /**
   * @returns {Promise<Object>}
   */
  async getCmsLayoutsDefault() {
    return process.server
      ? JSON.parse(require('fs').readFileSync(`src/client/static/data/cms/${this.#locale}/layouts/default.json`, 'utf8'))
      : await axios.get(`/data/cms/${this.#locale}/layouts/default.json?_=${this.version}`).then(response => response.data)
  }

  /**
   * @returns {Promise<Object[]>}
   */
  async getCmsPagesCampaigns() {
    return process.server
      ? JSON.parse(require('fs').readFileSync(`src/client/static/data/cms/pages/campaigns.json`, 'utf8'))
      : await axios.get(`/data/cms/pages/campaigns.json?_=${this.version}`).then(response => response.data)
  }

  /**
   * @returns {Promise<Object[]>}
   */
  async getShippingCountriesIndex() {
    return process.server
      ? JSON.parse(require('fs').readFileSync(`src/client/static/data/shipping-countries/${this.#locale}/index.json`, 'utf8'))
      : await axios.get(`/data/shipping-countries/${this.#locale}/index.json?_=${this.version}`).then(response => response.data)
  }

  /**
   * @returns {Promise<Object[]>}
   */
  async getCmsSearchPagesIndex() {
    return process.server
      ? JSON.parse(require('fs').readFileSync(`src/client/static/data/cms/${this.#locale}/search-pages/index.json`, 'utf8'))
      : await axios.get(`/data/cms/${this.#locale}/search-pages/index.json?_=${this.version}`).then(response => response.data)
  }

  /**
   * @param {string} locale
   * @returns {Object}
   */
  getFirstMainCategoryByLocale(locale) {
    return this.categoriesIndex?.[locale]?.[0]
  }

  /**
   * @param {string} categoryId
   * @param {string} locale
   * @returns {Object|string}
   */
  getCategoryUrlPathById(categoryId, locale) {
    return this.categoryMappingsIndex?.[categoryId]
      ? this.categoryMappingsIndex[categoryId]
      : `/${locale}/${CATEGORY_PAGE_NAME}`
  }
}
