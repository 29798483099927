import { ImageCacheService } from '@loavies/utils'

import { BambuserService } from '~/services/bambuser/bambuser-service'
import { PersonalisationService } from '~/services/personalisation/personalisation-service'
import { BloomreachService } from '~/services/tracking/bloomreach-service'
import { NewsletterService } from '~/services/newsletter/newsletter-service'
import { WishlistService } from '~/services/wishlist/wishlist-service'
import { UserService } from '~/services/user/user-service'
import { UserAddressService } from '~/services/user/user-address-service'
import { GtmService } from '~/services/tracking/gtm-service'
import { TrackingService } from '~/services/tracking/tracking-service'
import { GrinService } from '~/services/tracking/grin-service'
import { BambuserTrackingService } from '~/services/bambuser/bambuser-tracking-service'
import { CartService } from '~/services/cart/cart-service'
import { CartItemsService } from '~/services/cart/cart-items-service'
import { CartTotalsService } from '~/services/cart/cart-totals-service'
import { CartDiscountService } from '~/services/cart/cart-discount-service'
import { CartDonationService } from '~/services/cart/cart-donation-service'
import { CartShippingAddressService } from '~/services/cart/cart-shipping-address-service'
import { CartShippingMethodService } from '~/services/cart/cart-shipping-method-service'
import { CartPaymentService } from '~/services/cart/cart-payment-service'
import { OrderService } from '~/services/order/order-service'
import { RecentlyViewedService } from '~/services/recently-viewed/recently-viewed-service'
import { WalletService } from '~/services/wallet/wallet-service'
import { LocalizationService } from '~/services/localization/localization-service'
import { ScrollLockService } from '~/services/scroll-lock/scroll-lock-service'
import { OverlayService } from '~/services/overlay/overlay-service'
import { ProductCardSettingService } from '~/services/product/product-card-setting-service'
import { SocialRetailService } from '~/services/social-retail/social-retail-service'
import { MollieInitializationService } from '~/services/mollie/mollie-initialization-service'

export default async (context, inject) => {
  /**
   * @typedef ClientNuxtContext
   * @type {Object}
   * @property {GtmService} $gtmService
   * @property {BloomreachService} $bloomreachService
   * @property {BambuserTrackingService} $bambuserTrackingService
   * @property {GrinService} $grinService
   * @property {TrackingService} $trackingService
   * @property {WishlistService} $wishlistService
   * @property {UserService} $userService
   * @property {BambuserService} $bambuserService
   * @property {NewsletterService} $newsletterService
   * @property {ImageCacheService} $imageCacheService
   * @property {PersonalisationService} $personalisationService
   * @property {UserAddressService} $userAddressService
   * @property {CartService} $cartService
   * @property {CartItemsService} $cartItemsService
   * @property {CartTotalsService} $cartTotalsService
   * @property {CartShippingAddressService} $cartShippingAddressService
   * @property {CartDiscountService} $cartDiscountService
   * @property {CartDonationService} $cartDonationService
   * @property {CartShippingMethodService} $cartShippingMethodService
   * @property {CartPaymentService} $cartPaymentService
   * @property {OrderService} $orderService
   * @property {RecentlyViewedService} $recentlyViewedService
   * @property {WalletService} $walletService
   * @property {LocalizationService} $localizationService
   * @property {ScrollLockService} $scrollLockService
   * @property {OverlayService} $overlayService
   * @property {ProductCardSettingService} $productCardSettingService
   * @property {SocialRetailService} $socialRetailService
   * @property {MollieInitializationService} $mollieInitializationService
   */

  /**
   * @typedef {ServerNuxtContext, ClientNuxtContext} NuxtContext
   */

  const services = {
    gtmService: new GtmService(),
    bloomreachService: new BloomreachService(context),
    bambuserTrackingService: new BambuserTrackingService(),
    grinService: new GrinService(),
    trackingService: new TrackingService(context),
    wishlistService: new WishlistService(context),
    userService: new UserService(context),
    bambuserService: new BambuserService(context),
    newsletterService: new NewsletterService(context),
    imageCacheService: new ImageCacheService(),
    personalisationService: new PersonalisationService(context),
    userAddressService: new UserAddressService(context),
    cartService: new CartService(context),
    cartItemsService: new CartItemsService(context),
    cartTotalsService: new CartTotalsService(context),
    cartShippingAddressService: new CartShippingAddressService(context),
    cartDiscountService: new CartDiscountService(context),
    cartDonationService: new CartDonationService(context),
    cartShippingMethodService: new CartShippingMethodService(context),
    cartPaymentService: new CartPaymentService(context),
    orderService: new OrderService(context),
    recentlyViewedService: new RecentlyViewedService(context),
    walletService: new WalletService(context),
    localizationService: new LocalizationService(context),
    scrollLockService: new ScrollLockService(context),
    overlayService: new OverlayService(context),
    productCardSettingService: new ProductCardSettingService(context),
    socialRetailService: new SocialRetailService(context),
    mollieInitializationService: new MollieInitializationService(context),
  }

  const serviceKeys = Object.keys(services)

  // Inject services into the Vue context
  serviceKeys.forEach((key) => {
    inject(key, services[key])
  })

  // Run init in each service
  for (let i = 0; i < serviceKeys.length; i++) {
    const key = serviceKeys[i]
    await services[key].init?.()
  }
}
