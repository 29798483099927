import { render, staticRenderFns } from "./product-shout-out.vue?vue&type=template&id=7b38f4a2"
import script from "./product-shout-out.vue?vue&type=script&lang=js"
export * from "./product-shout-out.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppLabel: require('/opt/build/repo/src/client/components/app-label/app-label.vue').default})
