import { CheckoutApiService } from '~/services/checkout/checkout-api-service'
import { PixleeApiService } from '~/services/pixlee/pixlee-api-service'
import { ProductApiService } from '~/services/product/product-api-service'
import { ProductSkuConverterService } from '~/services/product/product-sku-converter-service'
import { CategoryApiService } from '~/services/category/category-api-service'
import { ThesaurusApiService } from '~/services/thesaurus/thesaurus-api-service'
import { ResponsiveImageService } from '~/services/responsive-image-service'
import { DateTimeService } from '~/services/utils/date-time-service'
import { CurrencyService } from '~/services/utils/currency-service'
import { CountryService } from '~/services/country/country-service'
import { DiscountService } from '~/services/utils/discount-service'
import { CanonicalService } from '~/services/seo/canonical-service'
import { PaymentMethodImageService } from '~/services/checkout/payment-method-image-service'
import { NewsletterApiService } from '~/services/newsletter/newsletter-api-service'
import { EmailValidatorService } from '~/services/validators/email-validator-service'
import { WishlistApiService } from '~/services/wishlist/wishlist-api-service'
import { UserApiMapperService } from '~/services/user/user-api-mapper-service'
import { UserApiService } from '~/services/user/user-api-service'
import { CheckoutApiMapperService } from '~/services/checkout/checkout-api-mapper-service'
import { ProductHelperService } from '~/services/product/product-helper-service'
import { BambuserMapperService } from '~/services/bambuser/bambuser-mapper-service'
import { MagentoApiService } from '~/services/core/magento-api-service'
import { StaticDataService } from '~/services/core/static-data-service'
import { SearchablePagesService } from '~/services/search/searchable-pages-service'
import { ProductBundleService } from '~/services/product/product-bundle-service'
import { ProductCarouselService } from '~/services/product/product-carousel-service'
import { ReloavedListingApiService } from '~/services/reloaved/reloaved-listing-api-service'
import { ProductImageHelperService } from '~/services/product/product-image-helper-service'
import { FilterHelperService } from '~/services/filter/filter-helper-service'
import { LoaviesClusterApiService } from '~/services/core/loavies-cluster-api-service'
import { GiftcardService } from '~/services/giftcard/giftcard-service'

export default async (context, inject) => {
  /**
   * @typedef ServerNuxtContext
   * @type {Object}
   * @property store
   * @property {Object} env
   * @property {Object} i18n
   * @property {Object} app
   * @property {Object} $sentry
   * @property {StaticDataService} $staticDataService
   * @property {MagentoApiService} $magentoApiService
   * @property {EmailValidatorService} $emailValidatorService
   * @property {UserApiMapperService} $userApiMapperService
   * @property {CheckoutApiMapperService} $checkoutApiMapperService
   * @property {CheckoutApiService} $checkoutApiService
   * @property {PaymentMethodImageService} $paymentMethodImageService
   * @property {PixleeApiService} $pixleeApiService
   * @property {CategoryApiService} $categoryApiService
   * @property {ThesaurusApiService} $thesaurusApiService
   * @property {DateTimeService} $dateTimeService
   * @property {CurrencyService} $currencyService
   * @property {ProductApiService} $productApiService
   * @property {ProductSkuConverterService} $productSkuConverterService
   * @property {ResponsiveImageService} $responsiveImageService
   * @property {DiscountService} $discountService
   * @property {CountryService} $countryService
   * @property {CanonicalService} $canonicalService
   * @property {NewsletterApiService} $newsletterApiService
   * @property {WishlistApiService} $wishlistApiService
   * @property {UserApiService} $userApiService
   * @property {ProductHelperService} $productHelperService
   * @property {BambuserMapperService} $bambuserMapperService
   * @property {SearchablePagesService} $searchablePagesService
   * @property {ProductBundleService} $productBundleService
   * @property {ProductCarouselService} $productCarouselService
   * @property {ReloavedListingApiService} $reloavedListingApiService
   * @property {ProductImageHelperService} $productImageHelperService
   * @property {FilterHelperService} $filterHelperService
   * @property {LoaviesClusterApiService} $loaviesClusterApiService
   * @property {GiftcardService} $giftcardService
   */

  const services = {
    staticDataService: new StaticDataService(context.i18n.locale),
    magentoApiService: new MagentoApiService(context),
    emailValidatorService: new EmailValidatorService(),
    userApiMapperService: new UserApiMapperService(context),
    checkoutApiMapperService: new CheckoutApiMapperService(context),
    checkoutApiService: new CheckoutApiService(context),
    paymentMethodImageService: new PaymentMethodImageService(context),
    pixleeApiService: new PixleeApiService(context),
    categoryApiService: new CategoryApiService(context),
    thesaurusApiService: new ThesaurusApiService(context),
    dateTimeService: new DateTimeService(context.i18n.locale),
    currencyService: new CurrencyService(context.i18n.locale),
    productApiService: new ProductApiService(context),
    productSkuConverterService: new ProductSkuConverterService(),
    responsiveImageService: new ResponsiveImageService(),
    discountService: new DiscountService(),
    countryService: new CountryService(context),
    canonicalService: new CanonicalService(context),
    newsletterApiService: new NewsletterApiService(context),
    wishlistApiService: new WishlistApiService(context),
    userApiService: new UserApiService(context),
    productHelperService: new ProductHelperService(context),
    bambuserMapperService: new BambuserMapperService(context),
    searchablePagesService: new SearchablePagesService(context),
    productBundleService: new ProductBundleService(context),
    productCarouselService: new ProductCarouselService(context),
    reloavedListingApiService: new ReloavedListingApiService(context),
    productImageHelperService: new ProductImageHelperService(context),
    filterHelperService: new FilterHelperService(),
    loaviesClusterApiService: new LoaviesClusterApiService(context),
    giftcardService: new GiftcardService(context),
  }

  const serviceKeys = Object.keys(services)

  // Inject services into the Vue context
  serviceKeys.forEach((key) => {
    inject(key, services[key])
  })

  // Run init in each service
  for (let i = 0; i < serviceKeys.length; i++) {
    const key = serviceKeys[i]
    await services[key].init?.()
  }
}
