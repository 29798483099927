
  import { mapGetters } from 'vuex'

  export default {
    props: {
      simple: {
        type: Boolean,
        default: false,
      },
      text: {
        type: String,
        default: '',
      },
      productHasDiscount: {
        type: Boolean,
        default: false,
      },
      productCategories: {
        type: Array,
        default: () => [],
      },
    },
    computed: {
      ...mapGetters({
        isAuthenticated: 'user/isAuthenticated',
      }),
      globalProductShoutOuts() {
        return this.$staticDataService.cmsLayoutsDefault?.globalProductShoutOuts ?? []
      },
      productOccursInShoutOutCategories() {
        return !!this.productCategories.find(category => this.globalProductShoutOuts.find(shoutOut => +shoutOut.categoryId === category.category_id))
      },
      showShoutOut() {
        return !!this.text || !!this.globalShoutOut
      },
      globalShoutOut() {
        let shoutOut = null

        // The flow to determine which global shout-out is shown is as follows:
        // There can be multiple global shout-outs, with different conditions.
        // - The first shout-out with the most true conditions will be shown
        // - If there are 2 shout-outs with different conditions e.g. 1 shout
        // with the 'userIsAuthenticated' condition and 1 shout out with the
        // 'productIsOnSale' condition. The 'userIsAuthenticated' is seen as more important.
        // As fallback there can be a shout-out with no conditions.
        if (this.isAuthenticated && this.productOccursInShoutOutCategories && this.productHasDiscount) {
          shoutOut = this.globalProductShoutOuts.find(shoutOut =>
            shoutOut.userIsAuthenticated
            && shoutOut.productIsOnSale
            && !!this.productCategories.find(category => category.category_id === +shoutOut.categoryId),
          )
        }

        if (shoutOut) {
          return shoutOut
        }

        if (this.isAuthenticated && this.productOccursInShoutOutCategories) {
          shoutOut = this.globalProductShoutOuts.find(shoutOut =>
            shoutOut.userIsAuthenticated
            && !shoutOut.productIsOnSale
            && !!this.productCategories.find(category => category.category_id === +shoutOut.categoryId),
          )
        }

        if (shoutOut) {
          return shoutOut
        }

        if (this.isAuthenticated && this.productHasDiscount) {
          shoutOut = this.globalProductShoutOuts.find(shoutOut =>
            shoutOut.userIsAuthenticated
            && shoutOut.productIsOnSale
            && !shoutOut.categoryId,
          )
        }

        if (shoutOut) {
          return shoutOut
        }

        if (this.isAuthenticated) {
          shoutOut = this.globalProductShoutOuts.find(shoutOut =>
            shoutOut.userIsAuthenticated
            && !shoutOut.productIsOnSale
            && !shoutOut.categoryId,
          )
        }

        if (shoutOut) {
          return shoutOut
        }

        if (this.productOccursInShoutOutCategories && this.productHasDiscount) {
          shoutOut = this.globalProductShoutOuts.find(shoutOut =>
            !shoutOut.userIsAuthenticated
            && shoutOut.productIsOnSale
            && !!this.productCategories.find(category => category.category_id === +shoutOut.categoryId),
          )
        }

        if (shoutOut) {
          return shoutOut
        }

        if (this.productOccursInShoutOutCategories) {
          shoutOut = this.globalProductShoutOuts.find(shoutOut =>
            !shoutOut.userIsAuthenticated
            && !shoutOut.productIsOnSale
            && !!this.productCategories.find(category => category.category_id === +shoutOut.categoryId),
          )
        }

        if (shoutOut) {
          return shoutOut
        }

        if (this.productHasDiscount) {
          shoutOut = this.globalProductShoutOuts.find(shoutOut =>
            !shoutOut.userIsAuthenticated
            && shoutOut.productIsOnSale
            && !shoutOut.categoryId,
          )
        }

        if (shoutOut) {
          return shoutOut
        }

        return this.globalProductShoutOuts.find(shoutOut => !shoutOut.userIsAuthenticated && !shoutOut.productIsOnSale && !shoutOut.categoryId)
      },
      shoutOutText() {
        return this.globalShoutOut?.text ?? this.text
      },
    },
  }
